import React from "react";

function ErrorMessage({ err }) {
  return (
    <div>
      <h4 className="m-2 text-lg">Error</h4>
      <ul className="text-red-500">
        {err &&
          err.map((msg) => {
            return <li>{msg}</li>;
          })}
      </ul>
    </div>
  );
}

export default ErrorMessage;
