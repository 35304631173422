export const classNames = {
    input: {
      regular : "bg bg-slate-100 shadow-lg shadow-inner appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-[#5B97EA] focus:shadow-outline",
      textArea: "bg bg-slate-100 shadow-lg shadow-inner appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-[#5B97EA] focus:shadow-outline",
    },
    label      : "block text-gray-700 text-sm font-bold mb-2",
    button     : "mb-4 mx-auto border p-2 px-4 my-2 rounded-md bg bg-blue-500 text-white",
    form       : "md:w-[85%] w-[100%] bg-white shadow-xl rounded md:p-6 p-8 mx-auto",
    formWrapper: "md:w-[85%] w-[100%] flex flex-col flex-wrap  mx-auto gap-3",
    flexItem   : "mb-4",
    imageLabel : "mt-2 md:ml-3 block text-gray-500 font-bold italic text-sm"
  };
  