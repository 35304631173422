import { useState, useEffect } from "react";
import PartnerForm from "./pages/PartnerForm";
import { supabase } from "./supabaseClient";
import { BiLoaderCircle } from "react-icons/bi";

function Account({ session }) {
  const [loading, setLoading]     = useState(true);
  const [userId, setUserId]       = useState(null);
  const [itemId, setItemId]       = useState(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [error, setError]         = useState(null);

  useEffect(() => {
    getProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const getProfile = async () => {
    try {
      setLoading(true);
      const { user } = session;
      let { data, error, status } = await supabase
        .from("profiles")
        .select()
        .eq("id", user.id)
        .single();
      setUserId(data.id);
      if (error && status !== 406) {
        setError(error);
      }
      if (data.itemId) {
        setItemId(data.itemId);
      } else {
        setIsNewItem(true);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div aria-live="polite" className="p-12">
      {!error && loading && (
        <div className="w-[85%] my-40 mx-auto">
          <BiLoaderCircle
            color     = "lightBlue"
            className = "mx-auto animate-spin"
            size      = {100}
          />
        </div>
      )}
      {!error && !loading && (
        <PartnerForm
          isNewItem    = {isNewItem}
          setIsNewItem = {setIsNewItem}
          itemId       = {itemId}
          userId       = {userId}
        />
      )}
    </div>
  );
}

export default Account;
