import { supabase } from "../supabaseClient";
import { classNames } from "../utils/classNames";
function Header() {
  const handleLogOut = (event) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to sign out?")) {
      supabase.auth.signOut();
    }
  };
  return (
    <div className="shadow-md flex md:flex-row flex-col items-center justify-evenly text-center md:p-4 p-2">
      <div className="flex-1">
        <img
          src    = "https://uploads-ssl.webflow.com/634da4a9e528a855732297db/6352e4328a1b4664c21c9f8a_long%20logo%20-%20800x200%20black.png"
          width  = {280}
          height = {80}
          alt    = "Botsplash Logo"
        />
      </div>
      <div className="flex-1">
        <h1 className="md:text-3xl text-xl">Botsplash Partner Portal</h1>
      </div>
      <div className="flex-1">
        <button
          type      = "button"
          className = {classNames.button}
          onClick   = {handleLogOut}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
}

export default Header;
