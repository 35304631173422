import { useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../utils/classNames";
import ErrorMessage from "../components/ErrorMessage";

function FormSubmitted() {
  const navigate  = useNavigate();
  const { state } = useLocation();
  return (
    <div className="w-full h-screen max-h-screen text-center">
      <div className="relative top-[30%] w-[20rem] rounded-md flex flex-col mx-auto shadow-lg border p-4">
        <>
          {!state.error.message ? (
            <h2 className="my-4">Update Successful!</h2>
          ) : (
            <ErrorMessage err={state.error.message} />
          )}
          <button className={classNames.button} onClick={() => navigate(`/`)}>
            Back
          </button>
        </>
      </div>
    </div>
  );
}

export default FormSubmitted;
