import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FormSubmitted from "./pages/FormSubmitted";
import Header from "./components/Header";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Header />
    <div className="w-full h-screen">
      <div className="wrapper mx-auto md:p-0 p-0">
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/submitted" element={<FormSubmitted />} />
        </Routes>
      </div>
    </div>
  </BrowserRouter>
);

