import { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import UserAuth from "./pages/UserAuth";
import Account from "./Account";

export default function App() {
  const [session, setSession] = useState(null);
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <div className="container mx-auto">
      {!session ? (
        <UserAuth />
      ) : (
        <Account key={session.user.id} session={session} />
      )}
    </div>
  );
}
