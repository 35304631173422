import { supabase } from "../supabaseClient";
import { Auth, ThemeSupa } from "@supabase/auth-ui-react";
function UserAuth({view}) {
  return (
    <div className="lg:w-1/2 md:w-full w-4/5 md:mt-20 mt-20 mx-auto bg-none">
      <Auth
        supabaseClient={supabase}
        appearance={{className: {button: "bg bg-[#1A5B9B] hover:bg-white hover:text-[#1A5B9B] text-white border border-[#1A5B9B]  "},theme: ThemeSupa }}
        theme="light"
        providers={[]}
        view="sign_in"
      />
    </div>
  );
}


export default UserAuth;