import { supabase } from "../supabaseClient";

const BASE_URL = process.env.NODE_ENV === 'development' ? "http://localhost:4000/api" : `${process.env.REACT_APP_BASE_URL}`
const headers  = { "Content-Type": "application/json" };

async function getCollectionItems(itemId) {
  const url     = `${BASE_URL}/items/${itemId}`;
  const options = {
    method: "GET",
    headers,
  };
  const response = await fetch(url, options);
  const result   = await response.json();
  return result;
}

async function updateCollectionItem(company, itemId) {
  const url     = `${BASE_URL}/items/${itemId}`;
  const options = {
    method: "PUT",
    body  : JSON.stringify({ data: company }),
    headers,
  };
  const response = await fetch(url, options);
  const result   = await response.json();
  return result;
}

async function updateSupabaseUser(userId, item) {
  await supabase.from("profiles")
  .update({ itemId: item })
  .eq("id", userId)
}

async function createCollectionItem(company) {
  const url     = `${BASE_URL}/items`;
  const options = {
    method: "POST",
    body  : JSON.stringify({data: company}),
    headers
  };
  const response = await fetch(url, options);
  const result   = await response.json();
  return result;
}

export {
  getCollectionItems,
  updateCollectionItem,
  createCollectionItem,
  updateSupabaseUser,
};
