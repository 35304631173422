import React, { useEffect, useState } from "react";
import { getCollectionItems, updateCollectionItem, createCollectionItem, updateSupabaseUser } from "../utils/api.js";
import { useNavigate}  from "react-router-dom";
import { classNames }   from "../utils/classNames";
import {BiLoaderCircle} from "react-icons/bi"
import ImageModal from "../components/ImageModal.jsx";


function PartnerForm({ itemId, isNewItem, userId }) {
  const initialFormState = {
    name              : "",
    companylogo       : { url: " ", alt: " " },
    websiteurl        : "",
    companydescription: "",
    companyphone      : "",
    companyemailmain  : "",
    companyaddress    : "",
    accountrepname    : "",
    accountrepemail   : "",
    companycategory   : "",
    companyindustry   : "",
    tagline           : "",
    googlereviewslink : ""
  }
  const [loading, setLoading]             = useState(true)
  const [formData, setFormData]           = useState(initialFormState);
  const [modalIsActive, setModalIsActive] = useState(false)

  const navigate = useNavigate();

  const handleModalOpen = (event) => {
    event.preventDefault();
    setModalIsActive(!modalIsActive)
  }

  const handleLogoChange = (event) => {
    event.preventDefault();
    setFormData((prevState) => {
      const newState = { ...prevState };
      if (event.target.name === "url" || event.target.name === "alt") {
        newState.companylogo[event.target.name] = event.target.value;
      }
      return newState;
    });
  };

  const handleChange = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    const result = await updateCollectionItem(formData, itemId);
    navigate("/submitted", {
      state: {
        error: result,
      },
    });
  };

  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    const result = await createCollectionItem(formData);
    await updateSupabaseUser (userId, result.data._id)
    navigate("/submitted", {
      state: {
        error: result,
      },
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    async function loadCollectionItem() {
      try {
        const data = await getCollectionItems(itemId);
        const properties = data.items[0];
        setFormData({
          ...properties,
          companylogo: {
            url: properties?.companylogo?.url,
            alt: properties?.companylogo?.alt,
          }
        });
      } catch (err) {
        if (err.message !== "AbortError") {
          throw err;
        }
        console.log("Aborted!");
      } finally {
        setLoading(false)
      }
    }
    if(itemId) {
    loadCollectionItem();
    } else {
        setFormData(initialFormState)
        setLoading(false)
    }
    return () => abortController.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return (
    <div id="formcontainer" className="w-[100%]">
      {modalIsActive && (
        <ImageModal 
          setModalIsActive = {setModalIsActive}
          imageSrc         = {formData.companylogo.url}
          imageAlt         = {formData.companylogo.alt} 
        />
      )}
      {!loading && (
        <form className={classNames.form} onSubmit={isNewItem ? handleCreateSubmit : handleUpdateSubmit}>
          <div className={classNames.formWrapper}>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="name">Name:</label>
              <input
                className = {classNames.input.regular}
                type      = "text"
                id        = "name"
                name      = "name"
                value     = {formData.name}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="logo">Company logo:</label>
              <input
                className = {classNames.input.regular}
                type      = "url"
                id        = "logo"
                name      = "url"
                value     = {formData.companylogo.url}
                onChange  = {handleLogoChange}
                required
              />
              <label className={classNames.imageLabel} htmlFor="image_preview">logo preview:</label>
              <img
                onClick={handleModalOpen}
                className="md:ml-3 cursor-pointer"
                src       = {formData.companylogo.url}
                alt       = {formData.companylogo?.alt}
                width     = {200}
                name="image_preview"
                id="image_preview"
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="websiteurl">Website URL:</label>
              <input
                className = {classNames.input.regular}
                type      = "text"
                id        = "websiteurl"
                name      = "websiteurl"
                value     = {formData.websiteurl}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="companydescription">Profile description:</label>
              <textarea
                className = {classNames.input.textArea}
                id        = "companydescription"
                name      = "companydescription"
                value     = {formData.companydescription}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="companyaddress">Company Address:</label>
              <input
                className = {classNames.input.regular}
                id        = "companyaddress"
                name      = "companyaddress"
                value     = {formData.companyaddress}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="companyphone">Company Phone:</label>
              <input
                className = {classNames.input.regular}
                id        = "companyphone"
                name      = "companyphone"
                value     = {formData.companyphone}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="companyemailmain">Company Email:</label>
              <input
                className = {classNames.input.regular}
                id        = "companyemailmain"
                name      = "companyemailmain"
                value     = {formData.companyemailmain}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="companycategory">Company Category:</label>
              <select
                className = {classNames.input.regular}
                id        = "companycategory"
                name      = "companycategory"
                onChange  = {handleChange}
              >
                <option value = "Mortgage">   Mortgage   </option>
                <option value = "Insurance">  Insurance  </option>
                <option value = "Automotive"> Automotive </option>
                <option value = "Solar">      Solar      </option>
                <option value = "Real Estate">Real Estate</option>
                <option value = "Other">      Other      </option>
              </select>
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="companyindustry">Company Industry:</label>
              <select
                className = {classNames.input.regular}
                id        = "companyindustry"
                name      = "companyindustry"
                onChange  = {handleChange}
              >
                <option value = "Consultant/Advisor">  Consultant/Advisor  </option>
                <option value = "Pricing Engine">      Pricing Engine      </option>
                <option value = "Lead Gun Marketplace">Lead Gun Marketplace</option>
                <option value = "CRM/LMS">             CRM/LMS             </option>
                <option value = "Call Center BDC">     Call Center BDC     </option>
                <option value = "Referral Partner">    Referral Partner    </option>
              </select>
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="accountrepname">Account Representative Name:</label>
              <input
                className = {classNames.input.regular}
                id        = "accountrepname"
                name      = "accountrepname"
                value     = {formData.accountrepname}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="accountrepemail">Account Rep Email:</label>
              <input
                className = {classNames.input.regular}
                id        = "accountrepemail"
                name      = "accountrepemail"
                value     = {formData.accountrepemail}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="googlereviewslink">Google Reviews Link</label>
              <input
                className = {classNames.input.regular}
                id        = "googlereviewslink"
                name      = "googlereviewslink"
                value     = {formData.googlereviewslink}
                onChange  = {handleChange}
              />
            </div>
            <div className={classNames.flexItem}>
              <label className={classNames.label} htmlFor="tagline">Tagline</label>
              <input
                className = {classNames.input.regular}
                id        = "tagline"
                name      = "tagline"
                value     = {formData.tagline}
                onChange  = {handleChange}
              />
            </div>
            <div className="mb-4 mx-auto">
              <button className={classNames.button} type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
      {loading && (
        <div className="w-[85%] my-40 mx-auto">
          <BiLoaderCircle color="lightBlue" className="mx-auto animate-spin" size={100} />
        </div>
      )}
    </div>
  );
}

export default PartnerForm;
