import React from "react";
import { BiXCircle } from "react-icons/bi";

function ImageModal({ imageSrc, imgAlt, setModalIsActive }) {
  return (
    <div
      id="modal-container"
      onClick={() => setModalIsActive(false)}
      className="cursor-pointer fixed right-0 top-0 left-0 bottom-0  w-screen flex justify-center items-center bg-[rgba(0,0,0,0.75)]">
      <BiXCircle />
      <div className="max-w-[40rem] bg-white">
        <img src={imageSrc} alt={imgAlt} />
      </div>
    </div>
  );
}

export default ImageModal;
